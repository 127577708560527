
.contact-form {
    padding: 5rem;
}

.contact-form-button {
    color: #649CAA;
    width: 100%;
    border-color: #649CAA;
}

.contact-form-button:hover {
    color: white;
    background-color: #649CAA;
    border-color: #649CAA;
}

.contact-form-title {
    font-family: 'Lexend Deca', sans-serif;
    font-size: xx-large;
}

.contact-statement {
    padding-top: 4rem;
    padding-bottom: 4rem;
    text-align: center;
}

.contact-statement-title {
    font-family: 'Rajdhani', sans-serif;
    font-size: 4rem;
}

.contact-statement-highlight {
    color: #649CAA;
    font-size: x-large;
}

.contact-statement-title-highlight {
    color: #649CAA;
    font-size: 4rem;
}

.contact-statement-body {
    font-family: 'Lexend Deca', sans-serif;
    font-size: x-large;
}

.form-control {
    background-color: #F2F2F2;
}

.map {
    padding-left: 0;
    padding-right: 0;
}

label {
    color: #9b9292;
}

.red-inforight {
    background-color: #9B0506;
    padding: 5rem;
    text-align: center;
}

.red-inforight-title {
    color: white;
}

.red-inforight-copyright {
    color: wheat;
}

.row {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 0;
    padding-right: 0;
}
