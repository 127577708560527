.general-navbar-wrapper {
    background-color: rgba(0, 0, 0, 0.7);
    margin: 0;
    padding: 0;
}

.general-navbar-container-wrapper {
    margin: 0;
    padding: 0;
}

.brand-wrapper {
    background-color: #649CAA;
    height: 3rem;
    margin: 0;
    padding: 0;
    text-align: center;
    width: 9rem;
    
}

.navbar-brand {
    line-height: 3rem;
    margin: 0;
    padding: 0;
    white-space: nowrap;
}

.nav-links-text {
    font-family: 'Orbitron', sans-serif;
}