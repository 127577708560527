.home-banner {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-family: 'Rajdhani', sans-serif;
    padding-top: 3rem;
    padding-bottom: 3rem;
    text-align: center;
}

.home-button {
    padding-top: 2rem;
    padding-bottom: 2rem;
    text-align: center;
}

.home-logo {
    padding-top: 4rem;
    padding-bottom: 1rem;
    text-align: center;
}