.card-title {
    font-weight: bold;
    padding-top: 1rem;
}

.card-text {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.expertise-cards {
    background-color: #F8F8F8;
    font-family: 'Rajdhani', sans-serif;
    padding-bottom: 5rem;
    text-align: center;
}

.expertise-cards-title {
    color: #649CAA;
    font-family: 'Rajdhani', sans-serif;
    font-weight: bolder;
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.mission-statement {
    padding-top: 4rem;
    padding-bottom: 4rem;
    text-align: center;
}

.mission-statement-body {
    font-family: 'Lexend Deca', sans-serif;
    font-size: xx-large;
    font-weight: bold;
}

.mission-statement-highlight {
    color: #649CAA;
    font-size: xx-large;
}
.mission-statement-title {
    font-family: 'Rajdhani', sans-serif;
    font-weight: bolder;
}

.quotations {
    color: #649CAA;
    font-size: xx-large;
    font-weight: bold;
}

.testimony {
    padding-top: 6rem;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
}

.testimony-author {
    color: white;
    font-size: large;
    font-weight: lighter;
    text-align: center;
}

.testimony-main {
    padding-top: 12rem;
    padding-bottom: 12rem;
    padding-left: 5rem;
    padding-right: 5rem;
}

.testimony-text {
    color: white;
    font-size: xx-large;
    font-weight: bold;
}

