.clients {
    background-color: #333333;
    text-align: center;
}

.clients-carousel {
    padding-top: 2rem;
    padding-bottom: 5rem;
}

.clients-title {
    color: white;
    font-family: 'Lexend Deca', sans-serif;
    padding-top: 5rem;
}

.red-contact-us {
    background-color: #9B0506;
    padding: 2rem;
    text-align: center;
}

.red-contact-us-title {
    color: white;
    padding-bottom: 2rem;
}

.team-cards {
    padding-bottom: 10rem;
}

.team-card-title {
    font-size: x-large;
    padding: 0;
}

.team-card-text {
    font-family: 'Orbitron', sans-serif;
    font-size: larger;
    color: #649CAA;
    padding: 0;
}
.team-statement {
    padding-top: 4rem;
    padding-bottom: 4rem;
    text-align: center;
}

.team-statement-body {
    font-family: 'Lexend Deca', sans-serif;
    font-size: xx-large;
    font-weight: bold;
}

.team-statement-title-highlight {
    color: #649CAA;
    font-size: 3rem;
    font-weight: bold;
}

.team-statement-title {
    font-family: 'Rajdhani', sans-serif;
    font-size: 3rem;
    font-weight: bold;
}

.testimony-parallax {
    padding: 0;
}

.testimony-parallax-author {
    font-style: italic;
}

.testimony-parallax-main {
    color: white;
    padding-top: 20rem;
    padding-bottom: 20rem;
    padding-left: 10rem;
    padding-right: 10rem;
}

