.services-card {
    text-align: center;
}

.services-card-subtitle {
    font-weight: lighter;
}

.services-statement {
    padding-top: 4rem;
    padding-bottom: 4rem;
    text-align: center;
}

.services-statement-body {
    font-family: 'Lexend Deca', sans-serif;
    font-size: xx-large;
    font-weight: bold;
}

.services-statement-title-highlight {
    color: #649CAA;
    font-size: 3rem;
    font-weight: bold;
}

.services-statement-title {
    font-family: 'Rajdhani', sans-serif;
    font-size: 3rem;
    font-weight: bold;
}